import {
  FLAT_PICKER,
  TEXT_INPUT,
  SELECT_INPUT,
  TEXTAREA_INPUT,
  SELECT_CHILD_INPUT, L_CURRENCY_MASK_INPUT,
} from '@/views/components/DynamicForm/constants'
import { formatNumberToCurrencyView, getValueFromGivenObjectByKey } from '@core/utils/utils'

export default function config() {
  const MODULE_NAME = 'purchase-cr'
  const MODULE_NAME_ORDERS = 'orders'
  const MODULE_NAME_CATALOG = 'catalog'
  const MODULE_NAME_CLONE = 'cloneData'

  // ORDER INFORMATION
  const ORDER_INFORMATION_TITLE = 'Order Information'

  const customerInformation = {
    customer_id: {
      label: 'Supplier Name',
    },
    required_by: {
      type: FLAT_PICKER,
      label: 'Required by',
      hasOperationHours: true,
      options: {
        config: {
          'is-calendar-icon': true,
          minDate: new Date(),
        },
      },
    },
    billing_contact: {
      type: SELECT_CHILD_INPUT,
      label: 'Billing Contact',
      store: 'billingContactOnPurchase',
      rules: 'required',
      firstSelect: true,
      options: {
        label: 'fullName',
      },
    },
    billing_phone: {
      type: TEXT_INPUT,
      label: 'Billing Phone',
      placeholder: ' ',
    },
    billing_email: {
      type: TEXT_INPUT,
      label: 'Billing Email',
      placeholder: ' ',
    },
    billing_address: {
      type: TEXTAREA_INPUT,
      label: 'Billing Address',
      placeholder: ' ',
    },
    payment_term_id: {
      type: SELECT_INPUT,
      label: 'Payment Terms',
      store: 'paymentTermList',
      rules: 'required',
      options: {
        label: 'label',
      },
    },
    internal_account_notes: {
      type: TEXTAREA_INPUT,
      label: 'Internal account notes ( not visible to the supplier )',
      placeholder: ' ',
    },
    internal_order_reference: {
      type: TEXTAREA_INPUT,
      label: 'Internal Order Reference ( not visible to the supplier )',
      placeholder: 'Write notes here...',
    },
    external_order_notes: {
      type: TEXTAREA_INPUT,
      label: 'Order Notes ( visible to the supplier )',
      placeholder: 'Write notes here...',
    },
    attachments: {
      label: 'Attachments',
    },
  }

  // DISPATCH
  const DISPATCH_TITLE = 'Dispatch'

  const dispatch = {
    receiving_warehouse: {
      type: SELECT_INPUT,
      label: 'Receiving Warehouse',
      store: 'warehouseList',
      options: {
        label: 'label',
      },
      rules: 'required',
    },
    receive_method: {
      type: SELECT_INPUT,
      label: 'Receive Method',
      store: 'dispatchAndReturnMethodsList',
      options: {
        label: 'name',
      },
      rules: 'required',
    },
    dispatch_contact: {
      type: SELECT_CHILD_INPUT,
      label: 'Dispatch Contact',
      store: 'orderDispatchList',
      rules: 'required',
      options: {
        label: 'full_name',
      },
    },
    external_shipping_notes: {
      type: TEXTAREA_INPUT,
      label: 'Shipping Notes ( visible to the supplier )',
      placeholder: 'Write notes here...',
    },
  }

  // STOCK ITEMS
  const STOCK_ITEMS_TITLE = 'Stock Items'
  const RFQ_SENT_ACTION_BTN_PRINT = {
    title: 'Print',
    action: '',
  }
  const tableColumnsStockItems = [
    {
      key: 'actions',
      label: '',
      thStyle: { width: '12%', padding: '5px 12px' },
      tBodyThStyle: { width: '21%' },
    },
    {
      key: 'sku',
      label: 'SKU',
      thStyle: { width: '10%', padding: '5px 12px' },
      tBodyThStyle: { width: '10%' },
    },
    {
      key: 'mpn',
      label: 'MPN',
      thStyle: { width: '10%', padding: '5px 12px' },
      tBodyThStyle: { width: '20%' },
    },
    {
      key: 'supplier_sku',
      label: 'Supplier SKU',
      thStyle: { width: '12%', padding: '5px 12px' },
      tBodyThStyle: { width: '12%' },
    },
    {
      key: 'name',
      label: 'Name',
      thStyle: { width: '18%', padding: '5px 12px' },
      tBodyThStyle: { width: '1%' },
    },
    {
      key: 'units',
      label: 'Units',
      thStyle: { minWidth: '150px', textAlign: 'center', padding: '5px 12px' },
      tBodyThStyle: { width: '14%', textAlign: 'center' },
    },
    {
      key: 'last_price',
      label: 'Last Price',
      thStyle: { width: '10%', padding: '5px 12px' },
      tBodyThStyle: { width: '13%' },
    },
    {
      key: 'last_price_date',
      label: 'Last Purchase Date',
      thStyle: { width: '15%', padding: '5px 12px' },
      tBodyThStyle: { width: '13%' },
    },
  ]

  const purchaseTableAutoSuggestTableColumns = [
    {
      key: 'sku',
      label: 'SKU',
    },
    {
      key: 'name',
      label: 'Name',
    },
    {
      key: 'available',
      label: 'Available',
    },
    {
      key: 'other_locations',
      label: 'Other Locations',
    },
    {
      key: 'rate',
      label: 'Rate',
      getValue: (value, data) => formatNumberToCurrencyView(data.rates[0]?.rate) ?? '',
    },
  ]

  const suggestionTableColumnsSupplier = [
    {
      key: 'cust_name_dba',
      label: 'Company',
    },
    {
      key: 'first_name',
      label: 'Contact',
      getValue: (value, data) => `
      ${getValueFromGivenObjectByKey(data, 'default_contact.first_name', '—')} 
      ${getValueFromGivenObjectByKey(data, 'default_contact.last_name', '')} 
      `,
    },
  ]

  // OTHER CHARGES/ITEMS
  const OTHER_CHARGES_OR_ITEMS_TITLE = 'Other Charges/Items'

  const chargeModel = {
    name: '',
    unit: '1',
    discount: null,
    subtotal: null,
    tax: null,
    totals: null,
  }

  const chargeModelFields = {
    name: {
      type: TEXT_INPUT,
      label: '',
    },
    unit: {
      type: TEXT_INPUT,
      label: '',
    },
    price: {
      type: L_CURRENCY_MASK_INPUT,
      label: '',
      placeholder: '$0',
    },
    discount: {
      type: L_CURRENCY_MASK_INPUT,
      label: '',
      placeholder: '$0',
    },
    subtotal: {
      type: L_CURRENCY_MASK_INPUT,
      label: '',
      placeholder: '$0',
    },
    taxes: {
      type: L_CURRENCY_MASK_INPUT,
      label: '',
      placeholder: '$0',
    },
    totals: {
      type: L_CURRENCY_MASK_INPUT,
      label: '',
      placeholder: '$0',
    },
  }

  const purchaseTableAutoSuggestOtherItemsTableColumns = [
    {
      key: 'name',
      label: 'Name',
    },
    {
      key: 'available',
      label: 'Available',
    },
    {
      key: 'other_locations',
      label: 'Other Locations',
    },
    {
      key: 'rate',
      label: 'Rate',
    },
  ]

  // Store Mutations
  const storeMutationsList = [
    {
      mutation: 'SET_ORDERS_INFORMATION_FORM',
      payload: {},
    },
    {
      mutation: 'SET_CUSTOMER_INFORMATION_FORM',
      payload: {
        attachments: [],
      },
    },
    {
      mutation: 'SET_INSURANCE_INFORMATION_FORM',
      payload: {},
    },
    {
      mutation: 'SET_DISPATCH_AND_RETURN_FORM',
      payload: {},
    },
    {
      mutation: 'SET_EVENT_INFORMATION_FORM',
      payload: {},
    },
    {
      mutation: 'SET_ORDER_ITEMS_RENTAL_TABLE',
      payload: {
        products: [],
      },
    },
    {
      mutation: 'SET_ORDER_ITEMS_OTHER_CHARGES_TABLE',
      payload: [],
    },
    {
      mutation: 'SET_TOTALS_FORM',
      payload: {},
    },
    {
      mutation: 'SET_ON_ERROR',
      payload: {},
    },
  ]

  const mapDataOnGetItem = (data, ctx) => {
    const customerInformationData = ctx.mappingFields(Object.keys(customerInformation), data, {
      required_by: Date.parse(data.required_by),
      orderID: data.id,
      billing_address: `${getValueFromGivenObjectByKey(data, 'billing_address.street', '—')}, ${getValueFromGivenObjectByKey(data, 'billing_address.line2', '—')}, ${getValueFromGivenObjectByKey(data, 'billing_address.city', '—')}, ${getValueFromGivenObjectByKey(data, 'billing_address.state.code', '—')}, ${getValueFromGivenObjectByKey(data, 'billing_address.zip', '—')}, ${getValueFromGivenObjectByKey(data, 'billing_address.country.three_letter', '—')} `,
      internal_account_notes: `${getValueFromGivenObjectByKey(data, 'supplier.customer_detail.comments')}`,
      // billing_contact: `${getValueFromGivenObjectByKey(data, 'billing_contact.first_name')} ${getValueFromGivenObjectByKey(data, 'billing_contact.last_name')}`,
      billing_phone: `${getValueFromGivenObjectByKey(data, 'billing_contact.phone_one')}`,
      billing_email: `${getValueFromGivenObjectByKey(data, 'billing_contact.email')}`,
      payment_term_id: getValueFromGivenObjectByKey(data, 'payment_term'),
      attachments: data.attachments,
    })

    const dispatchAndReturnData = ctx.mappingFields(Object.keys(dispatch), data, {
      receiving_warehouse: getValueFromGivenObjectByKey(data, 'dispatch_warehouse'),
      external_shipping_notes: getValueFromGivenObjectByKey(data, 'external_shipping_notes'),
      receive_method: getValueFromGivenObjectByKey(data, 'dispatch_method'),
      dispatch_contact: { ...getValueFromGivenObjectByKey(data, 'dispatch_contact'), full_name: `${getValueFromGivenObjectByKey(data, 'dispatch_contact.firstname')} ${getValueFromGivenObjectByKey(data, 'dispatch_contact.lastname')}` },
    })

    const mappedData = {
      ...customerInformationData,
      ...dispatchAndReturnData,
      products: data.products,
      otherCharges: data.other_charges,
    }

    ctx.$store.commit(`${MODULE_NAME}/SET_CUSTOMER_INFORMATION_FORM`, customerInformationData)
    ctx.$store.commit(`${MODULE_NAME}/SET_DISPATCH_AND_RETURN_FORM`, dispatchAndReturnData)
    ctx.$store.commit(`${MODULE_NAME}/SET_ORDER_ITEMS_RENTAL_TABLE`, data)
    ctx.$store.commit(`${MODULE_NAME}/SET_ORDER_ITEMS_OTHER_CHARGES_TABLE`, data.other_charges)
    ctx.$store.commit(`${MODULE_NAME_CLONE}/SET_PURCHASE_FORM_CLONE`, mappedData)
  }

  const FORM_BUTTON_TITLE_VOID = 'Void'
  const FORM_BUTTON_TITLE_PRINT = 'Print'
  const FORM_BUTTON_TITLE_SAVE_AS_DRAFT = 'Save as Draft'
  const FORM_BUTTON_TITLE_SEND_BY_EMAIL = 'Send by Email'
  const FORM_BUTTON_TITLE_QUOTE_RECEIVED = 'Quote Received'

  const DIRECT_REQUEST_FOR_QUOTE_ACTION_VOID = 'void'
  const DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_DRAFT = 'draft'
  const DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_SEND_EMAIL = 'send'
  const DIRECT_REQUEST_FOR_QUOTE_ACTION_QUOTE_RECEIVED = 'quote_received'

  return {
    dispatch,
    chargeModel,
    MODULE_NAME,
    DISPATCH_TITLE,
    mapDataOnGetItem,
    STOCK_ITEMS_TITLE,
    chargeModelFields,
    storeMutationsList,
    MODULE_NAME_ORDERS,
    customerInformation,
    tableColumnsStockItems,
    FORM_BUTTON_TITLE_VOID,
    ORDER_INFORMATION_TITLE,
    RFQ_SENT_ACTION_BTN_PRINT,
    FORM_BUTTON_TITLE_PRINT,
    OTHER_CHARGES_OR_ITEMS_TITLE,
    suggestionTableColumnsSupplier,
    FORM_BUTTON_TITLE_SEND_BY_EMAIL,
    FORM_BUTTON_TITLE_SAVE_AS_DRAFT,
    FORM_BUTTON_TITLE_QUOTE_RECEIVED,
    purchaseTableAutoSuggestTableColumns,
    DIRECT_REQUEST_FOR_QUOTE_ACTION_VOID,
    DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_DRAFT,
    DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_SEND_EMAIL,
    purchaseTableAutoSuggestOtherItemsTableColumns,
    DIRECT_REQUEST_FOR_QUOTE_ACTION_QUOTE_RECEIVED,
  }
}
